




import {Component, Prop, Vue} from 'vue-property-decorator';
import Charts from 'vue-echarts/components/ECharts.vue';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/tooltip';
import 'echarts/theme/macarons';

@Component({
  components: {
    'v-chart': Charts,
  },
})
export default class PieChart extends Vue {

  @Prop({default: []}) data!: any[];

  dataOptions: any = {
    tooltip: {
      trigger: 'item',
      formatter: '<b>{b}</b>: {c} ({d}%)',
    },
    series: [
      {
        type: 'pie',
        roseType: 'radius',
        radius: ['10%', '60%'],
        center: ['50%', '50%'],
        data: this.data,
        animationEasing: 'cubicInOut',
        animationDuration: 500,
        width: '100%',
        height: '100%',
      },
    ],
  };
}
